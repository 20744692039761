.SwitchCheckboxContainer{
    position: relative;
    width: 70px;
    height: 35px;
}

.SwitchCheckboxContainer .Switch{
    border-radius: 30px;
    position: relative;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.1);
    transition: ease all .3s;
}

.SwitchCheckboxContainer .Switch:hover{
    cursor: pointer;
}
.SwitchCheckboxContainer .Switch::before{
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    left: 5px;
    top: 5px;
    background-color: #fff;
    transition: ease all .3s;
}

.SwitchCheckboxContainer .Switch.checked::before{
    transform: translateX(calc(100% + 5px));
}
@media only screen and (max-width: 1280px) and (min-width: 720px) and (orientation: portrait){
    .SwitchCheckboxContainer{
        width: 80px;
    }
}